<template>
  <div id="home">
    <NavBar :currentRoute="'ScheduleFilter'"/>

    <v-container class="flex" id="receipt-container">
      <v-row justify="center">
        <v-col cols="12" md="11" lg="10" xl="9">
          <ScheduleFilterComponent/>
        </v-col>
      </v-row>
    </v-container>

    <About/>
  </div>
</template>

<script>
import About from "@/components/About";
import NavBar from "@/components/NavBar";
import UserService from "@/service/UserService";
import ScheduleFilterComponent from "@/components/schedule/ScheduleFilter";

export default {
  name: 'ScheduleFilterView',
  computed: {
    isCurrentUserAdmin() {
      return UserService.isCurrentUserAdmin();
    }
  },
  components: {
    NavBar,
    ScheduleFilterComponent,
    About,
  }
}
</script>

<style scoped>
#receipt-container {
  margin-bottom: 60px;
}
</style>