import axios from 'axios';
import {BackendError} from "@/exceptions/BackendError";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL + 'schedules/';

class ScheduleService {

	currentToken() {
		return localStorage.getItem('token');
	}

	insert(scheduleInsert) {
		console.log({"sending schedule insert: ": scheduleInsert})
		return axios.post(API_BASE_URL, scheduleInsert,
			{
				headers: {
					Authorization: 'Bearer ' + this.currentToken()
				}
			}).then(response => response.data)
			.catch((ex) => {
				console.log({"service error:" : ex})
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	getWaitingConfirmation() {
		return axios.get(API_BASE_URL + "waiting-verification",
			{
				headers: {
					Authorization: 'Bearer ' + this.currentToken()
				}
			}).then(response => response.data)
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data)
						throw new BackendError(ex.response.data.errorMessage);
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	getMyScheduleToday() {
		return axios.get(API_BASE_URL + "my-schedule-today",
			{
				headers: {
					Authorization: 'Bearer ' + this.currentToken()
				}
			}).then(response => response.data)
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data)
						throw new BackendError(ex.response.data.errorMessage);
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	verify(scheduleId) {
		return axios.put(API_BASE_URL + "verify", null,
			{
				params: {
					id: scheduleId,
				},
				headers: {
					Authorization: 'Bearer ' + this.currentToken()
				}
			}).then(response => response.data)
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	cancel(scheduleId) {
		return axios.put(API_BASE_URL + "cancel", null,
			{
				params: {
					id: scheduleId,
				},
				headers: {
					Authorization: 'Bearer ' + this.currentToken()
				}
			}).then(response => response.data)
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	filter(fromDate, toDate, workerId, userId, pageNumber, pageSize) {
		console.log("pn: " + pageNumber + ", ps: " + pageSize);
		return axios.get(API_BASE_URL + '/filter',
			{
				params: {
					fromDate: fromDate,
					toDate: toDate,
					coinId: workerId,
					userId: userId,
					pageNumber: 0,
					pageSize: 400,
				},
				headers: {
					Authorization: 'Bearer ' + this.currentToken()
				}
			}).then(response => response.data)
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("This is not implemented yet!");
			});
	}

	download(fromDate, toDate) {
		return axios.get(API_BASE_URL + '/download',
			{
				params: {
					fromDate: fromDate,
					toDate: toDate,
				},
				headers: {
					Authorization: 'Bearer ' + this.currentToken()
				}
			}).then(response => response.data)
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data)
						throw new BackendError(ex.response.data.errorMessage);
				}
				throw new BackendError("Problem gjatë shkarkimit!");
			});
	}

}

export default new ScheduleService();